// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { RecuperaPasswordModel } from "@/models/userModels";
import { mapActions } from "vuex";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";

@Component({
	methods: { ...mapActions(["loginUser"]) }
})
export default class passwordRecoverPage extends baseContextComponent {
	isLoading: boolean = false;
	recuperata: boolean = false;
	user: RecuperaPasswordModel = new RecuperaPasswordModel();
	emailRules = [
		(v: string) => {
			return !!v || "Campo obbligatorio";
		},
		(v: string | null) =>
			v == null || /.+@.+/.test(v) || "Formato non valido"
	];
	passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];

	checkForm(e: any) {
		var instance = this;

		e.preventDefault();
		instance.isLoading = true;
		api.doRecuperaPassword(instance.user)
			.then(token => {
				this.recuperata = true;
				instance.isLoading = false;
			}).finally(() => instance.isLoading = false);
	}
	get headerText(): string {
		return this.$appConfig.loginPage.headerText;
	}
	get htmlText(): string {
		// return this.$appConfig.loginPage.htmlText;
		return this.recuperata ? this.$i18n.t("msg.invioMailRecuperoPassword").toString() : this.$i18n.t("msg.inserireUsername").toString();
	}
}
